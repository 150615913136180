import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import ReactToPdf from 'react-to-pdf'
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import { image_base_url } from 'src/services/endpoints';
import moment from 'moment'
import FullScreenDialog from 'src/components/fullDailog';
import CustomDropDown from 'src/components/dropdown';
import { useLocation } from 'react-router-dom';
import { getOrdersListThunk } from 'src/redux/orders';
import hit from 'src/services/manager';
import { useRef } from 'react';
import { CSVLink, CSVDownload } from "react-csv";

// ----------------------------------------------------------------------

//0 : request
//1 : accepted
//2 :rejected
//3 : pickedup
//4 : delievered
//5 : received
//6 : Canceled
const orderTypes = {
    "0": "Requested",
    "1": "Accepted",
    "2": "Rejected",
    "3": "Picked Up",
    "4": "Delieverd",
    "5": "Received",
    "6": "Cancelled"
}
const listData = [
    { value: 0, label: "Requested" },
    { label: "Accepted", value: 1 },
    { label: "Rejected", value: 2 },
    { label: "Picked Up", value: 3 },
    { label: "Delieverd", value: 4 },
    { label: "Received", value: 5 },
    { label: "Cancelled", value: 6 },
    { label: "All", value: 10 }

]

const TABLE_HEAD = [
    { id: 'source', label: 'Source', alignRight: false },
    { id: 'destination', label: 'Destination', alignRight: false },
    { id: 'shipper', label: 'Shipper', alignRight: false },
    { id: 'transporter', label: 'Transporter', alignRight: false },
    { id: 'date', label: "Date&Time", alignRight: false },
    { id: 'Price', label: "Price", alignRight: false },
    { id: 'status', label: "Status", alignRight: false },
    { id: 'createdAt', label: "Registered", alignRight: false },
    { id: 'action', label: "Action", alignRight: true },

];

const TABLE_HEAD1 = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'txt', label: 'Log Message', alignRight: false },
    { id: 'extraData', label: 'ExtraData', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false },

];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user?.to?.address?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UserHistoryPage() {
    const dispatch = useDispatch()
    const location = useLocation()
    const [orders, setOrders] = useState([])
    const [selectedType, setSelectedType] = useState(0)
    const [data, setData] = useState({
        sOrders: [],
        cOrders: [],
        rOrders: []
    })
    const [logs, setLogs] = useState([])

    useEffect(() => {
        if (selectedType == 0) {
            setOrders(data.sOrders)
        } else if (selectedType == 1) {
            setOrders(data.cOrders)
        } else if (selectedType == 2) {
            setOrders(data.rOrders?.map(x => x.orderId))
        }
    }, [data, selectedType])
    useEffect(() => {
        if (location?.state?.user) {
            getOrdersData(location?.state?.user)
            getLogsData(location?.state?.user)
        }
    }, [location])

    const getOrdersData = async (userId) => {
        let orders = await hit("/admin/orders/" + userId, "get")
        if (!orders?.err) {
            setData(orders.data)
        }
    }

    const getLogsData = async (userId) => {
        let res = await hit("/activityLogs", "get", { user: userId })
        if (!res?.err) {
            setLogs(res.data)
        }
    }

    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [page1, setPage1] = useState(0);
    const [order, setOrder] = useState('asc');
    const [order1, setOrder1] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [selected1, setSelected1] = useState([]);
    const [orderBy, setOrderBy] = useState('firstName');
    const [orderBy1, setOrderBy1] = useState('firstName');
    const [filterName, setFilterName] = useState('');
    const [filterName1, setFilterName1] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [rowsPerPage1, setRowsPerPage1] = useState(200);
    const [selectedStatus, setSelectedStatus] = useState(10)
    const [selectedStatus1, setSelectedStatus1] = useState(10)
    const [selectedItem, setSelectedItem] = useState(null)
    const [csvData, setCsvData] = useState([])
    const [csvData1, setCsvData1] = useState([])

    useEffect(() => {
        if (orders) {
            let newCSV = []
            newCSV.push(['Sno', 'Source', 'Destination', 'Shipper', 'Transporter',"Receiver","Date&Time","Price","Status","Registered"])
            let i = 1
            for (let order of orders) {
                if(!order){
                    continue
                }
                const { _id, from, to, dimensions, subTotal, toDate, reciever, pickUp, pickupCharges, tax, status, requestBy, requested_by, requested_to, createdAt } = order;
                newCSV.push([i, from?.address, to?.address,(status == 0 && requestBy == "transporter") ? (requested_to?.email+"-"+requested_to.phone) : (requested_by?.email+"-"+requested_by?.phone),(status == 0 && requestBy == "transporter") ? (requested_by?.email+"-"+requested_by?.phone) : (requested_to?.email+"-"+requested_to?.phone),
                reciever?.email+"- +1"+reciever?.phone,moment(toDate, "MMM, DD, YYYY hh:mm a").format("ddd, DD, MMM"),
                Number(subTotal) + Number(pickUp ? pickupCharges : 0) + Number(tax),orderTypes[`${status}`],moment(createdAt).format("MMM, DD, YYYY")
            ])
                i++
            }
            setCsvData1(newCSV)
        }
    }, [orders])

    useEffect(() => {
        if (logs) {
            let newCSV = []
            newCSV.push(['Sno', 'Title', 'Log Message', 'ExtraData', 'Created At'])
            let i = 1
            for (let log of logs) {
                const { _id, from, to, title, txt, extraData, type, createdAt } = log;
                let data = "None"
                switch (type) {
                    case 1:
                        data = "Login from " + extraData?.address ?? ""
                        break
                    case 2:
                        data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                        break
                    case 3:
                        data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                        break
                    case 4:
                        data = "None"
                        break
                    case 5:
                        data = "None"
                        break
                    case 6:
                        data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                        break
                    case 7:
                        data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                        break
                }
                newCSV.push([i, title, txt, data, moment(createdAt).format("MM/DD/YYYY hh:mm a")])
                i++
            }
            setCsvData(newCSV)
        }
    }, [logs])

    useEffect(() => {
        dispatch(getOrdersListThunk())
    }, [location])

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRequestSort1 = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder1(isAsc ? 'desc' : 'asc');
        setOrderBy1(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orders?.map((n) => n?.firstName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleSelectAllClick1 = (event) => {
        if (event.target.checked) {
            const newSelecteds = logs?.map((n) => n?.firstName);
            setSelected1(newSelecteds);
            return;
        }
        setSelected1([]);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangePage1 = (event, newPage) => {
        setPage1(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };
    const handleChangeRowsPerPage1 = (event) => {
        setPage1(0);
        setRowsPerPage1(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleFilterByName1 = (event) => {
        setPage1(0);
        setFilterName1(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders?.length) : 0;

    const emptyRows1 = page1 > 0 ? Math.max(0, (1 + page1) * rowsPerPage1 - logs?.length) : 0;


    let filteredUsers = applySortFilter(orders, getComparator(order, orderBy), filterName);
    let filteredUsers1 = applySortFilter(logs, getComparator(logs, orderBy1), filterName1);

    const isNotFound = !filteredUsers.length && !!filterName;
    const isNotFound1 = !filteredUsers1.length && !!filterName1;

    filteredUsers = filteredUsers.filter(x => {
        if (selectedStatus == 10) {
            return true
        }
        return (x.status == selectedStatus)
    })

    filteredUsers1 = filteredUsers1.filter(x => {
        if (selectedStatus1 == 10) {
            return true
        }
        return (x.status == selectedStatus1)
    })

    const options = {
        orientation: 'landscape',
    };
    const ref = useRef();
    const ref1 = useRef();

    const getNameForLog=()=>{
        return (location?.state?.userData?.firstName == "" ? location?.state?.userData?.email : location?.state?.userData?.firstName)+"-"+location?.state?.userData?.phone+"-UserLogs.csv"
    }
    const getNameForOrderLog=()=>{
        return (location?.state?.userData?.firstName == "" ? location?.state?.userData?.email : location?.state?.userData?.firstName)+"-"+location?.state?.userData?.phone+"-OrderLogs.csv"
    }

    return (
        <>
            <Helmet>
                <title>Activities | Lugit</title>
            </Helmet>

            <div style={{paddingInline:"20px"}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {location?.state?.userData?.firstName == "" ? location?.state?.userData?.email : location?.state?.userData?.firstName} {location?.state?.userData?.phone} (User Activities)
                    </Typography>
                    <Stack direction="row" alignItems="center" >
                        <CSVLink style={{ color: "green" }} filename={getNameForLog()} data={csvData}>Download User Logs</CSVLink>
                        <CSVLink style={{ color: "green", marginLeft: "20px" }} filename={getNameForOrderLog()} data={csvData1}>Download Order Logs</CSVLink>

                        {/* <ReactToPdf targetRef={ref} filename="activities.pdf" options={options} scale={1.07}>
                        {({ toPdf }) => (
                            <Button onClick={(e)=>{
                                toPdf(e)
                            }}>
                                
                            </Button>
                        )}
                    </ReactToPdf> */}
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" mb={5}>
                    <Button onClick={() => setSelectedType(0)} variant="contained" color={selectedType == 0 ? 'success' : "secondary"} >
                        Shipper
                    </Button>
                    <Button onClick={() => setSelectedType(1)} sx={{ marginLeft: "10px" }} color={selectedType == 1 ? 'success' : "secondary"} variant="contained" >
                        Transporter
                    </Button>
                    <Button onClick={() => setSelectedType(2)} sx={{ marginLeft: "10px" }} color={selectedType == 2 ? 'success' : "secondary"} variant="contained" >
                        Receiver
                    </Button>
                </Stack>

                <Card >
                    <UserListToolbar headerText={"Orders"} showInput={false} showFilter={true} FilterComp={() => <CustomDropDown list={listData} value={selectedStatus} setValue={d => setSelectedStatus(d)} label={"Status"} />} placeholder={"Search Destination..."} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table ref={ref}>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={orders.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}

                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        if (!row) {
                                            return
                                        }
                                        const { _id, from, to, dimensions, subTotal, toDate, goingBy, pickUp, pickupCharges, tax, status, requestBy, requested_by, requested_to, createdAt } = row;
                                        const selectedUser = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell padding="checkbox" sx={{ paddingLeft: "5px" }}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {from?.address}
                                                </TableCell>
                                                <TableCell align="left">{to?.address}</TableCell>
                                                <TableCell align="left">{(status == 0 && requestBy == "transporter") ? requested_to?.email : requested_by?.email}</TableCell>
                                                <TableCell align="left">{(status == 0 && requestBy == "transporter") ? requested_by?.email : requested_to?.email}</TableCell>
                                                <TableCell align="left">
                                                    {/* {loginType} */}
                                                    {moment(toDate, "MMM, DD, YYYY hh:mm a").format("ddd, DD, MMM")}
                                                </TableCell>
                                                <TableCell align="left">${Number(subTotal) + Number(pickUp ? pickupCharges : 0) + Number(tax)}</TableCell>
                                                <TableCell align="left">{orderTypes[`${status}`]}</TableCell>
                                                <TableCell align="left">{moment(createdAt).format("MMM, DD, YYYY")}</TableCell>
                                                <TableCell align="right">
                                                    <Button onClick={() => {
                                                        setSelectedItem(row)
                                                    }} variant='contained' >Detail</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>

                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 200]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
                <Card sx={{ marginTop: 4 }} >
                    <UserListToolbar headerText={"User Logs"} showInput={false} placeholder={"Search Destination..."} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table ref={ref}>
                                <UserListHead
                                    order={order1}
                                    orderBy={orderBy1}
                                    headLabel={TABLE_HEAD1}
                                    rowCount={logs.length}
                                    numSelected={selected1.length}
                                    onRequestSort={handleRequestSort1}
                                    onSelectAllClick={handleSelectAllClick1}
                                />
                                <TableBody>
                                    {filteredUsers1.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1).map((row, index) => {
                                        const { _id, from, to, title, txt, extraData, type, createdAt } = row;
                                        const selectedUser = selected.indexOf(_id) !== -1;
                                        let data = "None"
                                        switch (type) {
                                            case 1:
                                                data = "Login from " + extraData?.address ?? ""
                                                break
                                            case 2:
                                                data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                                                break
                                            case 3:
                                                data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                                                break
                                            case 4:
                                                data = "None"
                                                break
                                            case 5:
                                                data = "None"
                                                break
                                            case 6:
                                                data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                                                break
                                            case 7:
                                                data = extraData?.packageSelected + (extraData?.packageType == "custom" ? `(${extraData?.dimensions?.l}x${extraData?.dimensions?.w})${extraData?.dimensions?.unit}` : "") + " Package"
                                                break
                                        }
                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell padding="checkbox" sx={{ paddingLeft: "5px" }}>
                                                    {/* <Checkbox checked={selectedUser} onChange={(event) => {
                              handleClick(event, _id)
                            }
                            } /> */}
                                                    {index + 1}
                                                </TableCell>

                                                <TableCell align="left">{title}</TableCell>
                                                <TableCell align="left">{txt}</TableCell>
                                                <TableCell align="left">{data}</TableCell>
                                                <TableCell align="left">
                                                    {/* {loginType} */}
                                                    {moment(createdAt).format("ddd, DD, MMM hh:mm a")}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>

                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 200]}
                        component="div"
                        count={filteredUsers1.length}
                        rowsPerPage={rowsPerPage1}
                        page={page1}
                        onPageChange={handleChangePage1}
                        onRowsPerPageChange={handleChangeRowsPerPage1}
                    />
                </Card>
            </div>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                <MenuItem sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
            <FullScreenDialog item={selectedItem} setItem={setSelectedItem} />
        </>
    );
}
