import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Input,
    TextField,
    Paper,
    Switch,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import CustomDropDown from 'src/components/dropdown';
import { getCryptoAdresses } from 'src/redux/addressCrypto';
import { setToast } from 'src/redux/toast';
import { setLoader } from 'src/redux/loader';
import hit from 'src/services/manager';
import AskDialog from 'src/components/ask';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'date', label: "Registered", alignRight: false },
    { id: 'status', label: "Status", alignRight: false },
    { id: 'action', label: "Action", alignRight: false },
];
const typeFilterList = [
    { label: "All", value: 'all' },
    { label: "Solana", value: 'Solana' },
    { label: "Ethereum", value: 'Ethereum' }
]
export default function CryptoAddress() {
    const [open, setOpen] = useState(null);

    const addressArr = useSelector(state => state?.addressCrypto)?.list
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(0);
    const [addType, setAddType] = useState('Solana')
    const [addAddress, setAddAddress] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedType, setSelectedType] = useState('all')
    const [open2, setOpen2] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        let data = {
            type: selectedType
        }
        dispatch(getCryptoAdresses(data))
    }, [selectedType])

    useEffect(() => {
        setTableData(addressArr)
    }, [addressArr])



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData?.length) : 0;


    const handleSubmit = async () => {
        try {
            let url = '/crypto/add_crypto_address'
            let payload = {
                type: addType,
                address: addAddress
            }
            if (selectedItem?._id) {
                payload.Id = selectedItem?._id
                url = '/crypto/update_crypto_address'
            }
            dispatch(setLoader(true))
            let res = await hit(url, 'post',  payload)
            if (!res.err) {
                dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))
                dispatch(getCryptoAdresses({ type: selectedType }))
                setAddAddress('')
                setAddType('Solana')
                setOpen(false)
            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const deleteAddress = async () => {
        try {
            let payload = {
                Id: selectedItem?._id
            }
            dispatch(setLoader(true))
            let res = await hit("/crypto/delete_crypto_address", "post", payload)
            if (!res.err) {
                dispatch(getCryptoAdresses({ type: selectedType }))
                setOpen2(false)
            } else {
                dispatch(setToast({ show: true, message: res.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }

    const handleSwitchChange = async (e, data) => {
        let value = e.target.checked
        try {
            let payload = {
                Id: data?._id,
                status: value ? '1' : '0'
            }
            dispatch(setLoader(true))
            let res = await hit('/crypto/update_crypto_address', 'post', payload)
            if (!res.err) {
                dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))
                dispatch(getCryptoAdresses({ type: selectedType }))
                setOpen(false)
            } else {
                dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
            }
        } catch (err) {

        } finally {
            dispatch(setLoader(false))

        }
    }
    return (
        <>
            <Helmet>
                <title>Activity | Lugit</title>
            </Helmet>

            <div className="" style={{ paddingInline: "20px" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Crypto Addresses
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
                        setOpen(true);
                        setAddAddress('')
                        setAddType('Solana');
                        setSelectedItem({})
                    }}>
                        Add New
                    </Button>
                </Stack>
                <Card>
                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 30, padding: 20 }}>
                        <CustomDropDown label={"Type"} list={typeFilterList} value={selectedType} setValue={setSelectedType} />
                    </div>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                />
                                <TableBody>
                                    {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { _id, address, status, type, updatedAt, createdAt } = row;
                                        return (
                                            <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                                                <TableCell padding="checkbox" sx={{ paddingLeft: "15px" }}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {address}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {type}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(createdAt).format("MMM, DD, YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Switch checked={status === 1 ? true : false} onChange={(e) => handleSwitchChange(e, row)} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 10 }}>
                                                        <Button onClick={() => {
                                                            { setSelectedItem(row); setOpen(true); setAddType(type); setAddAddress(address) }
                                                        }} variant='contained' color={'error'} >Edit</Button>
                                                        <Button onClick={() => {
                                                            { setSelectedItem(row); setOpen2(true) }
                                                        }} variant='contained' color={'error'} >Delete</Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>


                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 200]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
                <AskDialog open={open2} title="Delete Address" description={`Do you want to delete this Address?`} setOpen={setOpen2} onCancel={() => {
                    setOpen2(false)
                }} onOk={() => {
                    deleteAddress()
                }} />
                {
                    open &&
                    <Dialog
                        fullWidth
                        open={open}
                        onClose={() => { setOpen(false) }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Add Crypto Address</DialogTitle>
                        <div className="" style={{ padding: '20px 20px' }}>
                            <CustomDropDown value={addType} setValue={setAddType} label={'Type'} list={[
                                { label: "Solana", value: 'Solana' },
                                { label: "Ethereum", value: 'Ethereum' }
                            ]} />
                            <TextField style={{ marginTop: 30 }} fullWidth value={addAddress} onChange={e => {
                                setAddAddress(e.target.value)
                            }} name="address" label="Address" />
                        </div>
                        <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20, marginBottom: 20 }}>
                            <Button variant="contained" onClick={() => handleSubmit()}>{selectedItem?._id ? "Update" : "Submit"} </Button>
                            <Button variant="contained" onClick={() => { setOpen(false) }} color={'error'}>Cancel</Button>
                        </div>
                    </Dialog>
                }


            </div>
        </>
    );
}
