import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { TextField, Button, Box, Typography, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setLoader } from 'src/redux/loader';
import { setToast } from 'src/redux/toast';
import { useLocation } from 'react-router-dom';
import UserPass from 'src/components/userAndPass.js';
import hit from 'src/services/manager';

export default function SettingPage() {
    const dispatch = useDispatch()
    const location = useLocation()
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const [staffs, setStaffs] = React.useState([])
    const [tokens, setTokens] = useState([])

    const submit = async () => {
        if (password.trim() == "") {
            dispatch(setToast({ show: true, message: "password is empty", severity: "error" }))
            return
        }
        if (password != confirmPassword) {
            dispatch(setToast({ show: true, message: "confirm your password", severity: "error" }))
            return
        }
        dispatch(setLoader(true))
        let res = await hit("/admin/password", "patch", { password })
        if (!res.err) {
            setPassword("");
            setConfirmPassword("");
            dispatch(setToast({ show: true, message: "Updated Successfully!", severity: "success" }))
        } else {
            dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
        dispatch(setLoader(false))
    }

    const getStaff = async () => {
        dispatch(setLoader(true))
        let res = await hit("/admin/staffs", "get")
        if (!res.err) {
            setStaffs(res.data)
        } else {
            dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
        dispatch(setLoader(false))
    }

    const addStaff = async (data, selectedData) => {
        dispatch(setLoader(true))
        let res = null
        if (selectedData) {
            res = await hit("/admin/staffs/" + selectedData?._id, "patch", data)
        } else {
            res = await hit("/admin/staffs", "post", data)
        }
        if (!res.err) {
            getStaff()
            dispatch(setToast({ show: true, message: selectedData ? "Updated Successfully" : "Added Successfully!", severity: "success" }))
        } else {
            dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
        dispatch(setLoader(false))
    }

    const deleteStaff = async (id) => {
        dispatch(setLoader(true))
        let res = await hit("/admin/staffs/" + id, "delete")
        if (!res.err) {
            dispatch(setToast({ show: true, message: "deleted Successfully!", severity: "success" }))
            getStaff()
        } else {
            dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
        dispatch(setLoader(false))
    }

    const addToken = async (id) => {
        dispatch(setLoader(true))
        let res = await hit("/llps", "post")
        if (!res.err) {
            getTokens()
            dispatch(setToast({ show: true, message: "added Successfully!", severity: "success" }))
        } else {
            dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
        dispatch(setLoader(false))
    }

    const getTokens = async (id) => {
        dispatch(setLoader(true))
        let res = await hit("/llps", "get")
        if (!res.err) {
            setTokens(res?.data)
        } else {
            dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
        dispatch(setLoader(false))
    }

    const delteToken = async (id) => {
        dispatch(setLoader(true))
        let res = await hit("/llps/" + id, "delete")
        if (!res.err) {
            dispatch(setToast({ show: true, message: "deleted Successfully!", severity: "success" }))
            getTokens()
        } else {
            dispatch(setToast({ show: true, message: res?.msg, severity: "error" }))
        }
        dispatch(setLoader(false))
    }

    useEffect(() => {
        getStaff()
        getTokens()
    }, [location])

    return (
        <>
            <Helmet>
                <title> Dashboard: Sub | Lugit </title>
            </Helmet>
            <div style={{ paddingInline: "20px" }}>
                <Typography variant='h4'>Settings</Typography>
                <Typography mt={4}>Change Password</Typography>
                <Grid container spacing={2} mt={1}>
                    <Grid item sm={12} lg={4} >
                        <TextField value={password} onChange={e => {
                            setPassword(e.target.value)
                        }} name="tamount" label="New Password" sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <TextField value={confirmPassword} sx={{ width: "100%" }} onChange={e => {
                            setConfirmPassword(e.target.value)
                        }} name="cancellation" label="Confrim Password" />
                    </Grid>
                </Grid>
                <Box display="flex" mt={4}>
                    <Button onClick={() => {
                        submit()
                    }} variant='contained' >
                        Update Password
                    </Button>
                </Box>
                <Typography mt={4}>LLP Tokens</Typography>
                <Button sx={{ marginTop: "10px" }} onClick={() => {
                    addToken()
                }} variant='contained' >
                    Add
                </Button>
                {tokens?.map(x => {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                            <Typography sx={{ width: "400px" }}>{x?.token} {x?.user ? "(used)" : "(unused)"}</Typography>
                            <Button onClick={() => {
                                delteToken(x._id)
                            }} color='error' sx={{ marginLeft: "10px" }}>Delete</Button>
                        </Box>
                    )
                })}
                <Typography mt={4}>Staffs ({staffs?.length})</Typography>
                <Button sx={{ marginTop: "10px" }} onClick={() => {
                    setOpen(true)
                }} variant='contained' >
                    Add
                </Button>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                    <Typography fontWeight={"600"} sx={{ width: "400px" }}>Email</Typography>
                    <Typography fontWeight={"600"} sx={{ width: "400px" }}>Password</Typography>
                </Box>
                {staffs?.map(x => {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                            <Typography sx={{ width: "400px" }}>{x?.email}</Typography>
                            <Typography sx={{ width: "400px" }}>{x?.accessPass}</Typography>
                            <Button onClick={() => {
                                setOpen(true)
                                setSelected(x)
                            }} color='success' sx={{ marginLeft: "10px" }}>Edit</Button>
                            <Button onClick={() => {
                                deleteStaff(x._id)
                            }} color='error' sx={{ marginLeft: "10px" }}>Delete</Button>
                        </Box>
                    )
                })}
                <UserPass open={open} setOpen={setOpen} onSubmit={(data, selected) => {
                    setOpen(false)
                    setSelected(null)
                    addStaff(data, selected)
                }} onCancel={() => {
                    setSelected(null)
                    setOpen(false)
                }} selected={selected} title={selected ? "Update Staff" : "Add Staff"} description={"Please select access level you want to provide to the staff."} />
            </div>
        </>
    );
}