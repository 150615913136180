import { createSlice } from '@reduxjs/toolkit'
import hit from 'src/services/manager'

const initialState = {
  list: [],
  sortOrder: {
    orderBy: 'source',
    ascDesc: 'asc',
  },
}

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrderList: (state, action) => {
      state.list = action.payload
    },
    setSortOrder: (state, action) => {
      state.sortOrder = {
        orderBy: action.payload?.orderBy || 'source',
        ascDesc: action.payload?.ascDesc || 'asc',
      };
    },
  }
})

export const { setOrderList, setSortOrder } = ordersSlice.actions

export const getOrdersListThunk = (type) => {
  let url = "/admin/orders"
  if (type != "all") {
    url = url + `?type=${type}`
  }
  return async (dispatch) => {
    let res = await hit(url, "get")
    if (!res.err) {
      dispatch(setOrderList(res?.data))
    }
  }
}

export const sortOrderData = (data) => {
  return async (dispatch) => {
    dispatch(setSortOrder(data));
  };
};

export default ordersSlice.reducer